import API from "./API.ts";

export const api = new API();

export interface APIWarningResponse {
	msg: string;
}

export default async function createWarning(token: string, selected: string, steam: string, 
	date_active: string, reason: string
) {
	return await api.get<APIWarningResponse>(`createwarning/?token=${token}&job=${selected}` +
		`&steam=${steam}&date_active=${encodeURIComponent(date_active)}&reason=${encodeURIComponent(reason)}`);
}