import Header from '../../Header.js';
import getUser from '../../api/User.ts';
import getTracker from '../../api/Tracker.ts';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useParams } from "react-router-dom";
import { translations } from '../../util/Translations.ts';
import EmployeeData from '../../components/EmployeeData.js';
import CreateEmployeeDialog from '../../components/CreateEmployeeDialog.js';

function Tracker() {
    const { id } = useParams();
    const selected = translations[id]
    const [userData, setUserData] = useState({});
    const [jobData, setJobData] = useState({});
    const [steamId, setSteamId] = useState(null);
    let initialRank = 0;
    let foundEmployee = false;

    if (userData.exists == null) getUser(Cookies.get('access_token'))
        .then(res => {
            if (!res.exists) window.location.href = "/login"
            setUserData(res)
        });

    if (jobData.employees == null) getTracker(Cookies.get('access_token'), selected, true)
        .then(res => {
            if (res.rank == undefined) {
                res.rank = 99
            }

            setSteamId(res.employees[0].steam)
            setJobData(res)
        });

	return (
        <div>
            <Header userData={userData} jobData={jobData} />
            {userData.exists != null && jobData.employees != undefined ? <div className="m-6 mt-0 mr-16 ml-16 unhighlightable">
                <div className="mb-12 lg:ml-6 lg:mr-6">
                    <div data-emp="0" className="mt-0 grid lg:grid-cols-4 gap-3">
                        <div id={`${jobData.name}-employ`} className="h-min bg-zinc-800 rounded-lg px-4 py-3 grid gap-1">
                            {jobData.rank >= jobData.setting.modify_employees.value ? <CreateEmployeeDialog setJobData={setJobData} jobData={jobData} /> : ""}
                            <span id="ignore"><p className="mt-1.5 mb-0.5 uppercase text-xs text-zinc-400 font-bold">Colour Information</p></span>
                            <div className="grid text-xs mb-2">
                                <p className="text-zinc-400">Employee is on Leave of Absence</p>
                                <p className="text-red-500">Last clocked in more than 42 days ago</p>
                                <p className="text-orange-500">Last clocked in more than 28 days ago</p>
                                <p className="text-yellow-400">Last clocked in more than 14 days ago</p>
                                {jobData.name == "Casino" ? <p className="text-purple-500">Last clocked in more than 7 days ago</p> : ""}
                            </div>

                            {jobData.employees.map(employee => {
                                var title = false

                                if (initialRank != employee.rank) {
                                    title = true
                                    initialRank = employee.rank
                                }
                                // <span id="ignore">{title ? <p className="mt-1.5 mb-0.5 uppercase text-xs text-zinc-400 font-bold">{job.ranks[employee.rank - 1]}</p> : ""}</span>

                                return (
                                    <div>
                                        <span>{title ? <p class="mt-1.5 mb-0.5 uppercase text-xs text-zinc-400 font-bold">{jobData.ranks[employee.rank - 1]}</p> : ""}</span>

                                        <button 
                                            onClick={() => setSteamId(employee.steam)} 
                                            className={`${steamId == employee.steam ? "bg-[#439aa4] text-zinc-100" : `bg-zinc-700 hover:bg-[#439aa4] ${employee.last_active >= 42 ? "text-red-500" : (employee.last_active >= 28 ? "text-orange-500" : (employee.last_active >= 14 ? "text-yellow-400" : (employee.last_active >= 7 && (employee.rank == 1 && jobData.name == "Casino") ? "text-purple-500" : "text-zinc-200")))} bg-zinc-700 hover:bg-zinc-600 ${employee.loa ? "text-zinc-400" : ""}`} text-[0.8rem] w-full transition-colors px-3 py-1 rounded-lg text-left`}
                                        >
                                            {employee.name} ({employee.callsign})
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="h-min lg:col-span-3">
                            {jobData.employees.map(employee => {
                                if (steamId !== employee.steam) return;
                                foundEmployee = true
                                return <EmployeeData setJobData={setJobData} jobData={jobData} employee={employee} />
                            })}

                            {!foundEmployee ? <div id="loader" className="w-full mt-20 grid place-items-center">
                                <p className="text-zinc-300 text-4xl mb-2">No Employee Selected</p>
                                <p className="text-zinc-400 text-lg">Please select an employee from the sidebar to continue.</p>
                            </div> : ""}
                        </div>
                    </div>
                </div>
            </div> : ""}
        </div>
	);
}

export default Tracker;
