import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import createAbsence from '../api/CreateAbsence.ts';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { translations } from '../util/Translations.ts';
import { useParams } from "react-router-dom";
import getTracker from '../api/Tracker.ts';

export default function CreateAbsenceDialog(data) {
    const employee = data.employee
    const setJobData = data.setJobData

    const { id } = useParams();
    const selected = translations[id]

    let [isOpen, setIsOpen] = useState(false)
    let [reason, setReason] = useState("")
    let [additionalInfo, setAdditionalInfo] = useState("")
    let [startDate, setStartDate] = useState("")
    let [endDate, setEndDate] = useState("")

    function closeModal() {
        setIsOpen(false)
    }

    function saveChanges() {
        createAbsence(Cookies.get('access_token'), selected, employee.steam, reason, additionalInfo, startDate, endDate)
            .then(res => {
                console.log(res)
                if (res.error) {
                    toast.error(`Failed to create leave of absence!`)
                    closeModal()
                    return;
                }

                toast.success(`Successfully created a leave of absence for ${employee.name}`)
                closeModal()

                getTracker(Cookies.get('access_token'), selected)
                    .then(res => {
                        if (res.rank === undefined) {
                            res.rank = 99
                        }

                        setJobData(res)
                    })
            })
    }
    
    function openModal() {
        setIsOpen(true)
    }
    
    return (
        <>
            <button 
                onClick={openModal}
                type="button" 
                className="mt-1 px-3 py-1 text-xs text-zinc-300 rounded bg-zinc-700 border transition-colors border-zinc-800 hover:border-yellow-300"
            >
                Create LOA
            </button>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="unhighlightable relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-zinc-600 p-6 text-left align-middle shadow-xl transition-all">
                        <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-zinc-100"
                        >
                            Create Leave of Absence
                        </Dialog.Title>
                        
                        <div>
                            <p className="mt-2 text-sm text-zinc-300">Absence Reason</p>
                            <textarea placeholder="The reason for the employees absence." id="reason-editor" onChange={() => setReason(document.getElementById("reason-editor").value)} rows="3" style={{overflow:"auto", resize: "none"}} className="border border-[#80808a] placeholder-zinc-400 text-zinc-200  mt-1 w-full rounded-lg bg-zinc-500 py-2 px-3.5 text-left shadow-md focus:outline-none focus:border-[#80808a] focus-visible:ring-0 focus-visible:ring-offset-0 sm:text-sm" />
                        </div>

                        <div>
                            <p className="mt-2 text-sm text-zinc-300">Additional Info</p>
                            <textarea placeholder="Any additional info they provided." id="additional-editor" onChange={() => setAdditionalInfo(document.getElementById("additional-editor").value)} rows="3" style={{overflow:"auto", resize: "none"}} className="border border-[#80808a] placeholder-zinc-400 text-zinc-200  mt-1 w-full rounded-lg bg-zinc-500 py-2 px-3.5 text-left shadow-md focus:outline-none focus:border-[#80808a] focus-visible:ring-0 focus-visible:ring-offset-0 sm:text-sm" />
                        </div>

                        <div className="grid grid-cols-2 gap-3">
                            <div>
                                <p className="mt-2 text-sm text-zinc-300">Start Date</p>
                                <input placeholder="DD/MM/YYYY" id="start-editor" onChange={() => setStartDate(document.getElementById("start-editor").value)} className="border border-[#80808a] placeholder-zinc-400 text-zinc-200  mt-1 w-full rounded-lg bg-zinc-500 py-2 px-3.5 text-left shadow-md focus:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 sm:text-sm" />
                            </div>

                            <div>
                                <p className="mt-2 text-sm text-zinc-300">End Date</p>
                                <input placeholder="DD/MM/YYYY" id="end-editor" onChange={() => setEndDate(document.getElementById("end-editor").value)} className="border border-[#80808a] placeholder-zinc-400 text-zinc-200  mt-1 w-full rounded-lg bg-zinc-500 py-2 px-3.5 text-left shadow-md focus:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 sm:text-sm" />
                            </div>
                        </div>

                        <div className="mt-4">
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-lime-500 px-4 py-2 text-sm font-medium text-white hover:bg-lime-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-lime-500 transition-colors focus-visible:ring-offset-2"
                                onClick={saveChanges}
                            >
                            Submit Absence
                            </button>
                        </div>
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
                </Dialog>
            </Transition>
        </>
    )
}