import API from "./API.ts";

export const api = new API();

export interface APIUploadFileResponse {
	msg: string;
}

export default async function uploadFile(token: string, job: string, req: any) {
	return await api.get<APIUploadFileResponse>(`import/highlife/?token=${token}&job=${job}`, req);
}