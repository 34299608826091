import Header from '../../Header.js';
import getUser from '../../api/User.ts';
import getActiveWarnings from '../../api/Warnings.ts';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useParams } from "react-router-dom";
import { translations } from '../../util/Translations.ts';
import PageNotFound from '../PageNotFound.js';
import ActivityWarningDialog from '../../components/ActivityWarningDialog.js';

export default function PendingWarnings() {
    const { id } = useParams();
    const selected = translations[id]
    const [userData, setUserData] = useState({});
    const [jobData, setJobData] = useState({});

    if (selected != "Casino") return <PageNotFound />

    if (userData.exists == null) getUser(Cookies.get('access_token'))
        .then(res => {
            if (!res.exists) window.location.href = "/login"
            setUserData(res)
        });

    if (jobData.employees == null) getActiveWarnings(Cookies.get('access_token'), selected)
        .then(res => {
            if (res.rank == undefined) {
                res.rank = 99
            }

            setJobData(res)
        });

	return (
        <div>
            <Header userData={userData} jobData={jobData} />
            {userData.exists != null && jobData.employees != undefined ? <div className="m-6 mt-0 mr-16 ml-16 unhighlightable">
                <div className="mb-12 lg:ml-6 lg:mr-6">
                    <div class="bg-zinc-800 rounded-lg px-4 py-3 mb-3">
                        <p class="text-2xl font-semibold text-zinc-200 ml-2 mt-2">Live Alerts</p>
                        <p class="text-sm text-zinc-400 ml-2 mb-2">Want live alerts when a Employee is due an activity warning?</p>

                        <a target="_blank" href="https://discord.gg/wbcaePnt6P" className="px-4 py-1.5 rounded-lg bg-[#586aea] hover:bg-[#6e7deb] transition-colors text-zinc-200 ml-2">Join the Discord</a>
                        <p className="mb-2" />
                    </div>
                    <div class="bg-zinc-800 rounded-lg px-4 py-3">
                        <p class="text-2xl font-semibold text-zinc-200 ml-2 mt-2">Pending Activity Warnings</p>
                        <p class="text-sm text-zinc-400 ml-2">Here are all activity warnings that are waiting to be completed.</p>

                        <div class="relative overflow-x-auto shadow-md m-2 mt-4 rounded-lg">
                            <table class="w-full text-sm text-left text-zinc-300">
                                <thead class="text-xs uppercase bg-zinc-600 text-zinc-300">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            Employee
                                        </th>

                                        <th scope="col" class="px-6 py-3">
                                            Last Active
                                        </th>
                                        
                                        <th scope="col" class="px-6 py-3">
                                            Date Active
                                        </th>

                                        <th scope="col" class="px-6 py-3">
                                            Reason
                                        </th>
                                        
                                        <th scope="col" class="px-6 py-3">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {jobData.employees.map(employee => {
                                    if (((employee.rank == 0 && employee.last_active >= 7) || (employee.last_active >= 14)) && employee.last_active <= 19000) {
                                        return <ActivityWarningDialog employee={employee} jobData={jobData} setJobData={setJobData} />
                                    }
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> : ""}
        </div>
	);
}