import DeleteEmployeeDialog from './DeleteEmployeeDialog';
import EditEmployeeDialog from './EditEmployeeDialog';
import CreateAbsenceDialog from './CreateAbsenceDialog';

function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);

    var hDisplay = h > 0 ? h + (h === 1 ? "h, " : "h, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? "m" : "m") : "0m";
    return hDisplay + mDisplay;
}

export default function EmployeeData(data) {
    const employee = data.employee
    const jobData = data.jobData
    const setJobData = data.setJobData

    let total = 0
    let timeMap = {};
    let countMap = {};

    if (employee.sessions != null) {
        employee.sessions.map(session => {
            total += session.length
            if (Object.keys(timeMap).length < 12) {
                if (timeMap[session.month]) {
                    timeMap[session.month] = timeMap[session.month] + session.length
                    countMap[session.month] = countMap[session.month] + 1
                } else {
                    timeMap[session.month] = session.length
                    countMap[session.month] = 1
                }
            }

            return null;
        })
    }

    return (
        <>
            <div className="bg-zinc-800 px-4 py-3 rounded-lg">
            {employee.discord === "0" ? <div className="border bg-red-900 mb-4 m-2 px-2 py-2 border-red-500 text-zinc-200 rounded-lg"><i className="fa-xl fa-solid fa-triangle-exclamation text-red-500"></i> This employee has an incomplete profile and is missing a discord id</div> : ""}
            {employee.loa ? <div className="border bg-blue-900 mb-4 m-2 px-2 py-2 border-blue-500 text-zinc-200 rounded-lg"><i className="fa-xl fa-solid fa-circle-info text-blue-500 ml-1"></i> This employee currently has an active Leave of Absence.</div> : ""}

            <p className="text-2xl font-semibold text-zinc-200 ml-2 mt-2">{employee.name}</p>
            <p className="text-sm text-zinc-400 ml-2">{employee.steam}</p>
            <p className="text-sm text-zinc-400 ml-2">discord:{employee.discord}</p>

            <div className="ml-2 flex gap-1">
                {jobData.rank >= jobData.setting.modify_employees.value ? 
                    <EditEmployeeDialog setJobData={setJobData} employee={employee} jobData={jobData} /> : ""}
                    
                {jobData.rank >= jobData.setting.modify_employees.value ? 
                    <DeleteEmployeeDialog setJobData={setJobData} employee={employee} jobData={jobData} /> : ""}

                {jobData.rank >= jobData.setting.modify_loa.value ?
                    <CreateAbsenceDialog setJobData={setJobData} employee={employee} jobData={jobData} /> : ""}
            </div>

            <div className="mx-auto text-center">
                <div className="m-2 mt-4 grid gap-3 lg:grid-cols-4">
                    <div className="col-span-3">
                        <div className="grid gap-3 lg:grid-cols-3">
                            <div className="bg-zinc-700 rounded-lg">
                                <h1 className="mt-6 text-2xl tracking-tight font-semibold text-zinc-300"><i className="fa-regular fa-xl fa-clock"></i></h1>
                                <h1 className="mt-4 text-lg tracking-tight font-semibold text-zinc-300">Total Time</h1>
                                <h1 className="mb-4 text-lg tracking-tight font-semibold text-[#439aa4]">{secondsToHms(total)}</h1>
                            </div>

                            <div className="bg-zinc-700 rounded-lg">
                                <h1 className="mt-6 text-2xl tracking-tight font-semibold text-zinc-300"><i className="fa-solid fa-xl fa-hammer"></i></h1>
                                <h1 className="mt-4 text-lg tracking-tight font-semibold text-zinc-300">Latest Shift</h1>
                                <h1 className="mb-4 text-lg tracking-tight font-semibold text-[#439aa4]">{employee.sessions !== undefined ? new Date(employee.sessions[0].date).toLocaleString("en-GB").slice(0, -3) : "None"}</h1>
                            </div>

                            <div className="bg-zinc-700 rounded-lg">
                                <h1 className="mt-6 text-2xl tracking-tight font-semibold text-zinc-300"><i className="fa-solid fa-xl fa-eye"></i></h1>
                                <h1 className="mt-4 text-lg tracking-tight font-semibold text-zinc-300">Last Seen</h1>
                                {employee.real_last_active ? <h1 className="mb-4 mr-1 text-lg tracking-tight font-medium text-zinc-500 line-through inline-block">{employee.real_last_active}</h1> : ""}
                                <h1 className="mb-4 text-lg tracking-tight font-semibold text-[#439aa4] inline-block">{employee.last_active} days ago</h1>
                            </div>
                        </div>

                        <div className="mt-8 grid gap-3 lg:grid-cols-3">
                            {Object.keys(timeMap).map(key => {
                                const date = new Date(Number(key.split("-")[0]), Number(key.split("-")[1]) - 1, 1);  // 2009-11-10
                                const month = date.toLocaleString('default', { month: 'long' });

                                return (
                                    <div className="bg-zinc-700 rounded-lg">
                                        <h1 className="mt-4 text-lg tracking-tight font-semibold text-zinc-300">{month}, {Number(key.split("-")[0])}</h1>
                                        <h1 className="mb-4 text-lg tracking-tight font-semibold text-[#439aa4]">{secondsToHms(timeMap[key])} <span className="text-zinc-500">-</span> <span className="text-lime-600">{countMap[key]} shifts</span></h1>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="grid gap-3">
                        <div className="bg-zinc-700 rounded-lg px-3 py-2">
                            <p className="text-lg font-semibold text-zinc-200">Flagged Shifts</p>
                            <p className="text-xs italic text-zinc-300 mb-3">Shifts will show here if they are less then 30mins long and have been in the last 30 days.</p>
                            <div>
                                {employee.sessions !== undefined ? employee.sessions.map(session => {
                                    var old = new Date(session.date)
                                    var today = new Date();

                                    if (session.length < 1800 && Math.floor((today - old) / 1000 / 60 / 60 / 24) < 30) {
                                        return (
                                            <p className="text-sm mb-0.5 text-zinc-300">{new Date(session.date).toLocaleString("en-GB").slice(0, -3)} - {secondsToHms(session.length)}</p>
                                        )
                                    }

                                    return null;
                                }) : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}