
function PageNotFound() {
	return (
        <>
            <div id="loader" className="absolute w-full mt-20 grid place-items-center">
                <p className="text-zinc-400 text-6xl mb-4">404</p>
                <p className="text-zinc-400 text-lg">Hmmm something doesnt seem right, the requested page was not found.</p>
            </div>
        </>
	);
}

export default PageNotFound;
