import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { translations } from '../util/Translations.ts';
import { useParams } from "react-router-dom";
import getActiveWarnings from '../api/Warnings.ts';
import createWarning from '../api/CreateWarning.ts';

export default function ActivityWarningDialog(data) {
    const employee = data.employee
    const jobData = data.jobData
    const setJobData = data.setJobData

    const { id } = useParams();
    const selected = translations[id]

    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function confirmWarn() {
        if (employee.done) return
        createWarning(Cookies.get('access_token'), selected, employee.steam, new Date(employee.date_active).toLocaleString("en-GB").slice(0, -10), employee.last_active >= 42 ? "42 Days Inactivity" : employee.last_active >= 28 ? "28 Days Inactivity" : employee.last_active >= 14 ? "14 Days Inactivity" : employee.last_active >= 7 ? "7 Days Inactivity" : "unknown")
            .then(res => {
                if (res.error) {
                    toast.error(`Failed to create warning for ${employee.name}, ${res.error}`)  
                    return
                }

                closeModal();
                toast.success(`Created warning for ${employee.name}`)

                getActiveWarnings(Cookies.get('access_token'), selected)
                    .then(res => {
                        if (res.rank == undefined) {
                            res.rank = 99
                        }

                        setJobData(res)
                    })
            })
    }
    
    function openModal() {
        if (employee.done) return
        setIsOpen(true)
    }

    employee.done = false
    jobData.warnings.map(warning => {
        if (warning.reason == (employee.last_active >= 42 ? "42 Days Inactivity" : employee.last_active >= 28 ? "28 Days Inactivity" : employee.last_active >= 14 ? "14 Days Inactivity" : employee.last_active >= 7 ? "7 Days Inactivity" : "unknown")
            && warning.date_active == new Date(employee.date_active).toLocaleString("en-GB").slice(0, -10)
            && warning.steam == employee.steam) {
                console.log(employee)
                console.log(warning)
                employee.done = true
                return ""
            }
    })
    
    return (
        <>
            <tr onClick={openModal} class={!employee.done ? "border-b bg-zinc-700 hover:bg-[#4a4a52] cursor-pointer border-[#343434]" : "border-b bg-zinc-700 hover:bg-[#4a4a52] text-zinc-500 border-[#343434]"}>
                <th scope="row" class="px-6 py-3 whitespace-nowrap">
                    <div class={`font-semibold text-l`}>{employee.name}</div>
                </th>

                <td class="px-6">
                    {employee.last_active} days ago
                </td>
                
                <td class="px-6">
                    {new Date(employee.date_active).toLocaleString("en-GB").slice(0, -10)}
                </td>

                <td class="px-6">
                    {employee.last_active >= 42 ? "42 Days Inactivity" : employee.last_active >= 28 ? "28 Days Inactivity" : employee.last_active >= 14 ? "14 Days Inactivity" : employee.last_active >= 7 ? "7 Days Inactivity" : "unknown"}
                </td>

                <td class="px-6">
                    {!employee.done ? <span class="font-bold flex-initial rounded bg-red-600 text-xs px-2 py-0.5 m-1 mt-0 text-white">Awaiting Strike</span>
                        : <span class="font-bold flex-initial rounded bg-zinc-600 text-xs px-2 py-0.5 m-1 mt-0 text-zinc-300">Completed</span>}
                </td>
            </tr>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="unhighlightable relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-zinc-600 p-6 text-left align-middle shadow-xl transition-all">
                        <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-zinc-100"
                        >
                            Activity Warning for {employee.name}
                        </Dialog.Title>
                        <div className="mt-2">
                            <p className="text-sm text-zinc-300">
                                A pending warning is waiting to be given to {employee.name} for {employee.last_active >= 42 ? "42 Days Inactivity" : employee.last_active >= 28 ? "28 Days Inactivity" : employee.last_active >= 14 ? "14 Days Inactivity" : employee.last_active >= 7 ? "7 Days Inactivity" : "unknown"} as their last clock in was {employee.last_active} days ago.
                            </p>
                        </div>

                        <div className="mt-4">
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-lime-500 px-4 py-2 text-sm font-medium text-white hover:bg-lime-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-lime-500 transition-colors focus-visible:ring-offset-2"
                                onClick={confirmWarn}
                            >
                            Mark as Complete
                            </button>
                        </div>
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
                </Dialog>
            </Transition>
        </>
    )
}