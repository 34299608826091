import Header from '../../Header.js';
import getUser from '../../api/User.ts';
import getAbsences from '../../api/Absences.ts';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useParams } from "react-router-dom";
import { translations } from '../../util/Translations.ts';
import ViewAbsenceDialog from '../../components/ViewAbsenceDialog.js';

function Overview() {
    const { id } = useParams();
    const selected = translations[id]
    const [userData, setUserData] = useState({});
    const [jobData, setJobData] = useState({});

    if (userData.exists == null) getUser(Cookies.get('access_token'))
        .then(res => {
            if (!res.exists) window.location.href = "/login"
            setUserData(res)
        });

    if (jobData.loas == null) getAbsences(Cookies.get('access_token'), selected)
        .then(res => {
            if (res.rank == undefined) {
                res.rank = 99
            }

            setJobData(res)
        });

	return (
        <div>
            <Header userData={userData} jobData={jobData} />
            {userData.exists != null && jobData.loas != undefined ? <div className="m-6 mt-0 mr-16 ml-16 unhighlightable">
                <div className="mb-12 lg:ml-6 lg:mr-6">
                    <div class="bg-zinc-800 rounded-lg px-4 py-3">
                        <p class="text-2xl font-semibold text-zinc-200 ml-2 mt-2">Leave of Absences</p>
                        <p class="text-sm text-zinc-400 ml-2">View all leave of absences from users in this department.</p>

                        <div class="relative overflow-x-auto shadow-md m-2 mt-4 rounded-lg">
                            <table class="w-full text-sm text-left text-zinc-300">
                                <thead class="text-xs uppercase bg-zinc-600 text-zinc-300">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            Employee
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Reason
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Days
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Start
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Finish
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-right">
                                            Tags
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {jobData.loas.map(loa => <ViewAbsenceDialog loa={loa} jobData={jobData} />)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> : ""}
        </div>
	);
}

export default Overview;
