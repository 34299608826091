import Header from '../../Header.js';
import getUser from '../../api/User.ts';
import getSettings from '../../api/Settings.ts';
import uploadFile from '../../api/UploadFile.ts';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useParams } from "react-router-dom";
import { reverseTranslations, translations } from '../../util/Translations.ts';
import toast from 'react-hot-toast';
import uploadDiscordFile from '../../api/UploadDiscordFile.ts';

function Settings() {
    const { id } = useParams();
    const selected = translations[id]
    const [userData, setUserData] = useState({});
    const [jobData, setJobData] = useState({});

    if (userData.exists == null) getUser(Cookies.get('access_token'))
        .then(res => {
            if (!res.exists) window.location.href = "/login"
            setUserData(res)
        });

    if (jobData.settings == null) getSettings(Cookies.get('access_token'), selected)
        .then(res => {
            if (res.rank == undefined) {
                res.rank = 99
            }

            setJobData(res)
        });

    function updateClockinData() {
        const obj = document.getElementById("data-import-file")
		let fr = new FileReader();
		fr.onload = function () {
            toast.promise(uploadFile(Cookies.get('access_token'), selected, {
                method: "POST",
                body: fr.result
            }), {
                loading: `Updating clock-in data for ${selected}!`,
                success: `Clock-in data has been updated for ${selected}!`,
                error: `Failed to update clock-in data for ${selected}!`,
            });
		}

		fr.readAsText(obj.files[0]);
    }
    
	return (
        <div>
            <Header userData={userData} jobData={jobData} />
            {userData.exists != null && jobData.settings != undefined ? <div className="m-6 mt-0 mr-16 ml-16 unhighlightable">
                <div className="mb-12 lg:ml-6 lg:mr-6">
                    <div class="lg:col-span-4 grid lg:grid-cols-2 gap-3">
                        <div>
                            <div class="bg-zinc-800 rounded-lg px-4 py-3">
                                <p class="text-2xl font-semibold text-zinc-200 ml-2 mt-2">{jobData.name} Permissions</p>

                                <div class="grid gap-4 lg:grid-cols-2 mt-3 mb-2 ml-2 mr-2">
                                    {jobData.settings.map(stt => {
                                        const setting = jobData.setting[stt]

                                        return (
                                            <div>
                                                <p class="mb-1 uppercase text-xs text-zinc-400 font-bold">{setting.name} <span id={`${jobData.name}-${stt}-status`}></span></p>
                                                <select disabled id={`${jobData.name}-${stt}`} class="mt-1 border text-sm rounded-lg focus:ring-0 bg-zinc-700 transition-colors border-zinc-600 cursor-not-allowed focus:border-zinc-600 w-full block p-2.5 py-1 placeholder-zinc-400 text-zinc-300 focus:ring-blue-500 focus:border-blue-500">
                                                    {jobData.ranks.map(rank => {
                                                        if ((jobData.ranks.indexOf(rank) + 1) == setting.value) {
                                                            return <option selected value={jobData.ranks.indexOf(rank) + 1}>{rank}</option>
                                                        } else {
                                                            return <option value={jobData.ranks.indexOf(rank) + 1}>{rank}</option>
                                                        }
                                                    })}
                                                </select>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            {jobData.loa_enabled ? <div class="mt-3 bg-zinc-800 rounded-lg px-4 py-3">
                                <p class="text-2xl font-semibold text-zinc-200 ml-2 mt-2">{jobData.name} Leave of Absence Form</p>
                                <p class="text-sm text-zinc-400 ml-2">These settings control the Leave of Absence form managed by HighLife Job Tracker, it allows employees of the casino to submit a private Leave of Absence via a link.</p>

                                <div class="mt-4 ml-2 mr-2">
                                    <p class="mb-1 uppercase text-xs text-zinc-400 font-bold">Direct Link</p>
                                    <div class="flex gap-2">
                                        <textarea rows="1" style="overflow:auto; resize: none;" class="p-2.5 w-full text-sm rounded-lg bg-zinc-700 border-zinc-800 placeholder-gray-400 text-zinc-300" disabled>{jobData.loa_link}</textarea>
                                        <button onclick={`window.open("${jobData.loa_link}", "_blank")`} class="bg-zinc-700 hover:bg-zinc-600 text-zinc-300 hover:text-zinc-200 transition-colors w-12 rounded-lg">
                                            <i class="w-5 h-5 text-center mx-auto fa-solid fa-arrow-up-right-from-square"></i>
                                        </button>
                                    </div>

                                    <p class="mb-1 mt-2 uppercase text-xs text-zinc-400 font-bold">Form Footer <span id={`${jobData.name}-form-footer-status`}></span></p>
                                    <textarea rows="8" style="overflow:auto; resize: none;" class="p-2.5 w-full text-sm rounded-lg bg-zinc-700 border-zinc-800 placeholder-gray-400 text-zinc-300" placeholder="This field displays at the bottom of the Leave of Absence form and will give employees a brief description of how it works.">{jobData.loa_description}</textarea>
                                </div>
                            </div> : ""}

                            <div class="mt-3 bg-zinc-800 rounded-lg px-4 py-3">
                                <p class="text-2xl font-semibold text-zinc-200 ml-2 mt-2">{jobData.name} Data Import</p>
                                <p class="text-sm text-zinc-400 ml-2">Import clockin data, please only use this if you know how to.</p>
                                <input type="file" onChange={() => updateClockinData()} class="inline-block rounded-lg bg-zinc-700 text-xs m-2 w-86 text-zinc-400 border border-zinc-600" id="data-import-file" />
                            </div>
                        </div>

                        <div>
                            <div class="bg-zinc-800 rounded-lg px-4 py-3">
                                <p class="text-2xl font-semibold text-zinc-200 ml-2 mt-2">{jobData.name} Audit Log</p>
                                <p class="text-sm text-zinc-400 ml-2 mb-2">Looking for the Audit Log? This has recently moved due to technical reasons, Admin actions, employee creation, deletion and editing logs are now logged on our discord.</p>
                                
                                <a target="_blank" href="https://discord.gg/wbcaePnt6P" className="px-4 py-1.5 rounded-lg bg-[#586aea] hover:bg-[#6e7deb] transition-colors text-zinc-200 ml-2">Join the Discord</a>
                                <p className="mb-2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> : ""}
        </div>
	);
}

export default Settings;
