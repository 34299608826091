import API from "./API.ts";

export const api = new API();

export interface APIAbsencesResponse {
	id: string;
	permissions: string[];
	accessed: string;
}

export default async function getAbsences(token: string, selected: string) {
	return await api.get<APIAbsencesResponse>(`page/absences/?token=${token}&selected=${selected}`);
}