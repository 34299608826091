import API from "./API.ts";

export const api = new API();

export interface APIEditEmployeeResponse {
	msg: string;
}

export default async function editEmployee(token: string, job: string, steam: string, discord: string, 
	rank: number, callsign: string, name: string, edit: number) {
	return await api.get<APIEditEmployeeResponse>(`ediemployee/?token=${token}&job=${job}&steam=${steam}` +
		`&discord=${discord.trim()}&rank=${rank}&callsign=${callsign.trim()}&name=${name.trim()}&edit=${edit}`
	);
}