import API from "./API.ts";

export const api = new API();

export interface APIUploadFileResponse {
	msg: string;
}

export default async function uploadDiscordFile(token: string, job: string, format: string, req: any) {
	return await api.get<APIUploadFileResponse>(`import/?token=${token}&job=${job}&format=${format}`, req);
}