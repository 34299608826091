import Header from '../../Header';
import getUser from '../../api/User.ts';
import getOverview from '../../api/Overview.ts';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useParams } from "react-router-dom";
import { translations } from '../../util/Translations.ts';

function Overview() {
    const { id } = useParams();
    const selected = translations[id]
    const [userData, setUserData] = useState({});
    const [jobData, setJobData] = useState({});

    if (userData.exists == null) getUser(Cookies.get('access_token'))
        .then(res => {
            if (!res.exists) window.location.href = "/login"
            setUserData(res)
        });

    if (jobData.employees == null) getOverview(Cookies.get('access_token'), selected)
        .then(res => {
            if (res.rank == undefined) {
                res.rank = 99
            }

            setJobData(res)
        });

	return (
        <div>
            <Header userData={userData} jobData={jobData} />
            {userData.exists != null && jobData.employees != undefined ? <div className="m-6 mt-0 mr-16 ml-16 unhighlightable">
                <div className="mb-12 lg:ml-6 lg:mr-6">
                    <div className="bg-zinc-800 rounded-lg px-4 py-3">
                        <p className="text-2xl font-semibold text-zinc-200 ml-2 mt-2">Activity Overview</p>
                        <p className="text-sm text-zinc-400 ml-2">Overview of all employees and their activity for this month.</p>

                        {jobData.graphs != undefined ? jobData.graphs.map(graph => {
                            return <button key={graph.split(":||;")[1]} type="button" onClick={() => window.open(`${graph.split(":||;")[1]}`, `newwin`, `height=577px,width=1150px,titlebar=no,toolbar=no,scrollbars=no,resizable=no,menubar=no`)} className="mt-1 ml-2 px-3 py-1 text-xs text-zinc-300 rounded bg-zinc-700 border transition-colors border-zinc-800 hover:border-yellow-300">{graph.split(":||;")[0]}</button>
                        }) : ""}

                        <div className="grid grid-cols-5 gap-2 m-2 text-sm">
                            <p className="text-zinc-400 text-center">Leave of Absence</p>
                            <p className="text-red-500 text-center">Less than 1h</p>
                            <p className="text-orange-500 text-center">More than 1h</p>
                            <p className="text-yellow-400 text-center">More than 3h</p>
                            <p className="text-green-400 text-center">More than 5h</p>
                        </div>


                        <div className="border-b border-zinc-700 m-2"></div>
                        <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 m-2 text-sm">
                            {jobData.employees.map(employee => {
                                return (
                                    <div key={employee.steam} className={`text-left ${employee.loa ? "text-zinc-400" : (employee.month >= 18000 ? "text-green-400" : (employee.month >= 10800 ? "text-yellow-400" : (employee.month >= 3600 ? "text-orange-500" : "text-red-500")))}`}>
                                        {employee.name}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div> : ""}
        </div>
	);
}

export default Overview;
