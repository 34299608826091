import { Transition, Listbox } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

export default function EditEmployeeRank(data) {
    const jobData = data.jobData
    const selectedRank = data.selectedRank
    const setSelectedRank = data.setSelectedRank

    return (
        <>
            <Listbox value={selectedRank} onChange={setSelectedRank}>
                <div className="relative mt-1">
                <Listbox.Button className="border border-[#80808a] cursor-pointer relative w-full cursor-default rounded-lg bg-zinc-500 py-2 px-3.5 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className="block truncate text-zinc-200">{selectedRank}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-zinc-400"
                        aria-hidden="true"
                    />
                    </span>
                </Listbox.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-in duration-100"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="border border-[#80808a] absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-zinc-500 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {jobData.ranks.map((rank) => (
                        <Listbox.Option
                            key={jobData.ranks.indexOf(rank) + 1}
                            value={rank}
                            disabled={jobData.rank <= (jobData.ranks.indexOf(rank) + 1) ? true : false}
                            className={({ active }) =>
                                `relative ${jobData.rank <= (jobData.ranks.indexOf(rank) + 1) ? "bg-zinc-500 text-zinc-400 cursor-not-allowed" : "bg-zinc-500 cursor-pointer"} select-none py-1 px-3.5 pr-4 ${
                                active ? 'text-white' : 'text-zinc-300'}`
                            }
                        >
                            {({ selected }) => (
                                <>
                                    <span
                                        className={`block truncate ${
                                        selected ? 'font-medium' : 'font-normal'
                                        }`}
                                    >
                                        {rank}
                                    </span>
                                </>
                            )}
                        </Listbox.Option>
                    ))}
                    </Listbox.Options>
                </Transition>
                </div>
            </Listbox>
        </>
    )
}