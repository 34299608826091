export type Translation = keyof typeof translations
export const translations = {
	"casino": "Casino",
	"ems": "EMS",
	"dynasty": "Dynasty 8",
} as const;

export type RevTranslation = keyof typeof reverseTranslations
export const reverseTranslations = {
	"Casino": "casino",
	"EMS": "ems",
	"Dynasty 8": "dynasty",
} as const;