import API from "./API.ts";

export const api = new API();

export interface APIWarningsResponse {
	id: string;
	permissions: string[];
	accessed: string;
}

export default async function getWarnings(token: string, selected: string) {
	return await api.get<APIWarningsResponse>(`page/warnings/?token=${token}&selected=${selected}`);
}