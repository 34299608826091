import API from "./API.ts";

export const api = new API();

export interface APIDeleteEmployeeResponse {
	msg: string;
}

export default async function deleteEmployee(token: string, job: string, steam: string) {
	return await api.get<APIDeleteEmployeeResponse>(`delemployee/?token=${token}&job=${job}&steam=${steam}`);
}