import getUser from '../api/User.ts';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { reverseTranslations } from '../util/Translations.ts';

function HomePage() {
    const [userData, setUserData] = useState({});

    if (userData.exists == null) getUser(Cookies.get('access_token'))
        .then(res => {
            if (!res.exists) window.location.href = "/login"
            setUserData(res)
        });

	return (
        <div>
            {userData.exists != null ? <div className="h-screen unhighlightable grid grid-cols-1 lg:grid-cols-6">
                <div className="m-6 lg:col-span-2 lg:col-start-3 m-auto">
                    <div className="bg-zinc-800 rounded py-0.5">
                        <div className="m-3 justify-center">
                            <p className="text-zinc-300 text-2xl">Please select a Department</p>
                        </div>

                        <div className="border-b border-zinc-700 m-2"></div>

                        <div className={`unhighlightable mt-6 gap-3 grid grid-cols-1 lg:grid-cols-6`}>
                            {userData.permissions.map(perm => {
                                const key = reverseTranslations[perm];

                                return (
                                    <button key={key} onClick={() => window.location.href = `/dash/${key}/overview/`} className="text-xl lg:col-span-4 lg:col-start-2 rounded-lg bg-zinc-700 hover:bg-zinc-600 text-zinc-300 hover:text-zinc-200 transition-colors py-2">
                                        {perm}
                                    </button>
                                )
                            })}
                        </div>

                        <div className="m-3 mt-6">
                            <p className="text-xs text-zinc-300">HighLife Job Tracker has recieved an update to make the tool more streamlined to allow a larger amount of departments, please select the department you wish to view from your list above!</p>
                        </div>
                    </div>
                </div>
            </div> : ""}
        </div>
	);
}

export default HomePage;
