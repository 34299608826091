import API from "./API.ts";

export const api = new API();

export interface APICreateAbsenceResponse {
	msg: string;
}

export default async function createAbsence(token: string, job: string, steam: string, reason: string, 
	additional: string, start: string, end: string) {
	return await api.get<APICreateAbsenceResponse>(`createloa/?token=${token}&job=${job}&steam=${encodeURIComponent(steam)}` +
		`&reason=${encodeURIComponent(reason)}&additional=${encodeURIComponent(additional)}` + 
		`&start=${encodeURIComponent(start)}&end=${encodeURIComponent(end)}`
	);
}