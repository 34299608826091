import API from "./API.ts";

export const api = new API();

export interface APIOverviewResponse {
	id: string;
	permissions: string[];
	accessed: string;
}

export default async function getOverview(token: string, selected: string) {
	return await api.get<APIOverviewResponse>(`page/overview/?token=${token}&selected=${selected}`);
}