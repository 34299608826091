import { Dialog, Transition, Listbox } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import EditEmployeeRank from './EditEmployeeRank.js'
import editEmployee from '../api/EditEmployee.ts';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { translations } from '../util/Translations.ts';
import { useParams } from "react-router-dom";
import getTracker from '../api/Tracker.ts';

export default function CreateEmployeeDialog(data) {
    const jobData = data.jobData
    const setJobData = data.setJobData

    const { id } = useParams();
    const selected = translations[id]

    let [isOpen, setIsOpen] = useState(false)
    let [steam, setSteam] = useState("")
    let [selectedRank, setSelectedRank] = useState(jobData.ranks[0])
    let [callsign, setCallsign] = useState("")
    let [name, setName] = useState("")
    let [discord, setDiscord] = useState("")

    function closeModal() {
        setIsOpen(false)
    }

    function saveChanges() {
        editEmployee(Cookies.get('access_token'), selected, steam, discord, jobData.ranks.indexOf(selectedRank), callsign, name, 0)
            .then(res => {
                console.log(res)
                if (res.error) {
                    toast.error(`Failed to create new employee!`)
                    closeModal()
                    return;
                }

                toast.success(`Successfully created a new employee (${name})`)
                closeModal()

                getTracker(Cookies.get('access_token'), selected)
                    .then(res => {
                        if (res.rank == undefined) {
                            res.rank = 99
                        }

                        setJobData(res)
                    })
            })
    }
    
    function openModal() {
        setIsOpen(true)
    }
    
    return (
        <>
            <button 
                onClick={openModal}
                type="button" 
                className="px-3 py-1 text-xs text-zinc-300 rounded bg-zinc-700 border transition-colors border-zinc-800 hover:border-yellow-300"
            >
                Create Employee
            </button>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="unhighlightable relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-zinc-600 p-6 text-left align-middle shadow-xl transition-all">
                        <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-zinc-100"
                        >
                            Create New Employee
                        </Dialog.Title>
                        
                        <div>
                            <p className="mt-2 text-sm text-zinc-300">Steam Identifier</p>
                            <input id="steam-editor" placeholder="steam:11000014a47786c" onChange={() => setSteam(document.getElementById("steam-editor").value)} value={steam} className="border border-[#80808a] placeholder-zinc-400 text-zinc-200 mt-1 w-full rounded-lg bg-zinc-500 py-2 px-3.5 text-left shadow-md focus:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 sm:text-sm" />
                        </div>

                        <div>
                            <p className="mt-2 text-sm text-zinc-300">Discord Identifier</p>
                            <input id="discord-editor" placeholder="711974603387306490" onChange={() => setDiscord(document.getElementById("discord-editor").value)} value={discord} className="border border-[#80808a] placeholder-zinc-400 text-zinc-200 mt-1 w-full rounded-lg bg-zinc-500 py-2 px-3.5 text-left shadow-md focus:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 sm:text-sm" />
                        </div>

                        <div>
                            <p className="mt-2 text-sm text-zinc-300">Rank</p>
                            <EditEmployeeRank jobData={jobData} selectedRank={selectedRank} setSelectedRank={setSelectedRank} />
                        </div>

                        <div>
                            <p className="mt-2 text-sm text-zinc-300">Employee Callsign</p>
                            <input id="callsign-editor" placeholder="DC-01" onChange={() => setCallsign(document.getElementById("callsign-editor").value)} value={callsign} className="border border-[#80808a] placeholder-zinc-400 text-zinc-200 mt-1 w-full rounded-lg bg-zinc-500 py-2 px-3.5 text-left shadow-md focus:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 sm:text-sm" />
                        </div>

                        <div>
                            <p className="mt-2 text-sm text-zinc-300">Employee Name</p>
                            <input id="name-editor" placeholder="Teddy Cortelli" onChange={() => setName(document.getElementById("name-editor").value)} value={name} className="border border-[#80808a] placeholder-zinc-400 text-zinc-200 mt-1 w-full rounded-lg bg-zinc-500 py-2 px-3.5 text-left shadow-md focus:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 sm:text-sm" />
                        </div>

                        <div className="mt-4">
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-lime-500 px-4 py-2 text-sm font-medium text-white hover:bg-lime-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-lime-500 transition-colors focus-visible:ring-offset-2"
                                onClick={saveChanges}
                            >
                            Save Changes
                            </button>
                        </div>
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
                </Dialog>
            </Transition>
        </>
    )
}