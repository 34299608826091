import toast, { Toaster } from 'react-hot-toast';
import {Fragment, useState} from 'react'
import {Dialog, Disclosure, Popover, Transition} from '@headlessui/react'
import {
	ArrowPathIcon,
	Bars3Icon,
	ChartPieIcon,
	CursorArrowRaysIcon,
	FingerPrintIcon,
	SquaresPlusIcon,
	XMarkIcon,
} from '@heroicons/react/24/outline'
import {ChevronDownIcon, PhoneIcon, PlayCircleIcon} from '@heroicons/react/20/solid'
import { reverseTranslations, translations } from './util/Translations.ts';
import { ReactComponent as Logo} from './assets/logo.svg';
import getOverview from './api/Overview.ts';
import Cookies from 'js-cookie';

function App(data) {
	const id = data.id
	const href = window.location.href;
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

	if (data.userData.exists == null || data.jobData.rank == undefined) return;
	
	let links = [
		{name: "Overview", href: `/dash/${href.split("/")[4]}/overview`},
		{name: "Employee Tracker", href: `/dash/${href.split("/")[4]}/tracker`}
	]
	
	if (data.jobData.rank >= data.jobData.setting.modify_settings.value) {
		links.push({name: "Settings", href: `/dash/${href.split("/")[4]}/settings`})
	}
	
	const jobs = {name: "Jobs", href: "#", popover: []}
	const modules = {name: "Modules", href: "#", popover: []}
	data.userData.permissions.map(perm => {
		const key = reverseTranslations[perm];
		jobs.popover.push({name: perm, href: `/dash/${key}/overview`})
	})

	if (data.jobData.rank >= data.jobData.setting.view_loa.value) {
		modules.popover.push({name: "Leave of Absences", href: `/dash/${href.split("/")[4]}/absences`})
	}

	if (href.split("/")[4] === "casino") modules.popover.push({name: "Activity Warnings", href: `/dash/${href.split("/")[4]}/warnings`})
	if (modules.popover.length >= 1) links.push(modules)
	if (jobs.popover.length >= 2) links.push(jobs)

	return (
		<>
			<Toaster 
				containerclassNameName="unhighlightable"
				position="top-right"
				toastOptions={{
					style: {
						background: '#323545',
						color: '#fff',
					}
				}} 
			/>
			
			<header className="backdrop-blur bg-zinc-800 mb-12">
				<nav className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8" aria-label="Global">
					<div className="flex lg:flex lg:items-start">
						<a href="/" className="-m-1.5">
							<span className="sr-only">HighLife Activity Tracker</span>
							<div className="flex">
								<Logo className="inline-block h-16 w-16" />
								<span className="text-lg font-semibold mt-4 ml-2 text-zinc-200">HighLife Job Tracker</span>
							</div>
						</a>
					</div>
					<div className="flex lg:hidden">
						<button
							type="button"
							className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-100"
							onClick={() => setMobileMenuOpen(true)}
						>
							<span className="sr-only">Open main menu</span>
							<Bars3Icon className="h-6 w-6" aria-hidden="true"/>
						</button>
					</div>
					<Popover.Group className="hidden lg:flex lg:gap-x-8 lg:ml-12">
						{links.map((link, index) => {
							if (link.popover) {
								return (
									<Popover className="relative z-50" key={index}>
										<Popover.Button
											className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-zinc-200 hover:text-[#439aa4] focus:border-zinc-800 focus:outline-none transition-colors">
											{link.name}
											<ChevronDownIcon className="h-5 w-5 flex-none" aria-hidden="true"/>
										</Popover.Button>

										<Transition
											as={Fragment}
											enter="transition ease-out duration-200"
											enterFrom="opacity-0 translate-y-1"
											enterTo="opacity-100 translate-y-0"
											leave="transition ease-in duration-150"
											leaveFrom="opacity-100 translate-y-0"
											leaveTo="opacity-0 translate-y-1"
										>
											<Popover.Panel
												className="absolute -left-8 top-full z-10 mt-3 w-48 max-w-md overflow-hidden rounded-lg bg-zinc-700 shadow-lg">
												<div className="p-2">
													{link.popover.map((item, itemIndex) => (
														<a href={item.href} className="group relative flex items-center gap-x-6 rounded-lg p-2 text-sm leading-6 hover:bg-zinc-500" key={item.name}>
															<div className="flex-auto">
																<div className="block font-semibold text-zinc-50">
																	{item.name}
																</div>
															</div>
														</a>
													))}
												</div>
											</Popover.Panel>
										</Transition>
									</Popover>
								)
							} else {
								return (
									<a href={link.href} className="text-sm font-semibold leading-6 text-zinc-200 hover:text-[#439aa4] transition-colors"
									key={index}>
										{link.name}
									</a>
								)
							}
						})}
					</Popover.Group>
				</nav>
				<Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
					<div className="fixed inset-0 z-10"/>
					<Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-zinc-800 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-zinc-700">
						<div className="flex items-center justify-between">
							<a href="#" className="-m-1.5">
								<div className="">
									<Logo className="inline-block h-16 w-16" />
									<span className="text-lg font-semibold mt-4 ml-2 text-zinc-200">HighLife Job Tracker</span>
								</div>
							</a>
							<button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-300" onClick={() => setMobileMenuOpen(false)}>
								<span className="sr-only">Close</span>
								<XMarkIcon className="h-6 w-6" aria-hidden="true"/>
							</button>
						</div>
						<div className="mt-6 flow-root">
							<div className="-my-6 divide-y divide-gray-100/10">
								<div className="space-y-2 py-6">
									{links.map((link, index) => {
										if (link.popover) {
											return (
												<Disclosure as="div" className="-mx-3 " key={link.name}>
													{({open}) => (
														<>
															<Disclosure.Button
																className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-zinc-100 hover:bg-zinc-600">
																{link.name}
																<ChevronDownIcon
																	className={open ? 'rotate-180 h-5 w-5 flex-none' : 'h-5 w-5 flex-none'}
																	aria-hidden="true"
																/>
															</Disclosure.Button>
															<Disclosure.Panel className="mt-2 space-y-2">
																{link.popover.map((item, itemIndex) => (
																	<Disclosure.Button
																		key={item.name}
																		as="a"
																		href={item.href}
																		className="block rounded-lg py-1 pl-6 pr-3 text-sm font-semibold leading-7 text-zinc-100 hover:bg-zinc-600"
																	>
																		<div className="flex items-center">
																			<span className="ml-2">{item.name}</span>
																		</div>
																	</Disclosure.Button>
																))}
															</Disclosure.Panel>
														</>
													)}
												</Disclosure>
											)
										} else {
											return (
												<a className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-zinc-100 hover:bg-zinc-600" href={link.href} key={index}>
													{link.name}
												</a>
											)
										}
									})}
								</div>
							</div>
						</div>
					</Dialog.Panel>
				</Dialog>
			</header>
		</>
	);
}

export default App;
