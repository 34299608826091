import API from "./API.ts";

export const api = new API();

export interface APITrackerResponse {
	id: string;
	permissions: string[];
	accessed: string;
}

export default async function getTracker(token: string, selected: string, details: boolean) {
	return await api.get<APITrackerResponse>(`page/tracker/?token=${token}&selected=${selected}&details=${details}`);
}