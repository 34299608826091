import API from "./API.ts";

export const api = new API();

export interface APISettingsResponse {
	id: string;
	permissions: string[];
	accessed: string;
}

export default async function getSettings(token: string, selected: string) {
	return await api.get<APISettingsResponse>(`page/settings/?token=${token}&selected=${selected}`);
}