import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import API from "../api/API.ts";

function Login() {
    const [searchParams, setSearchParams] = useSearchParams();

    const location = window.location.origin;
    const url = `${API.APIBase}/auth/?res=${encodeURIComponent(location + "/")}`
    const code = searchParams.get("accessCode");
    
    if (code) {
        Cookies.set('access_token', code)
        window.location.href = "/";
    }

	return (
        <div>
            <div class="h-screen unhighlightable grid grid-cols-1 lg:grid-cols-6">
                <div class="m-6 lg:col-span-2 lg:col-start-3 m-auto">
                    {searchParams.get("error") != null ? () => {
                        return (
                            <div class="bg-zinc-800 rounded mb-3 border border-red-600">
                                <div class="m-3 mt-2 mb-2 text-sm">
                                    {searchParams.get("error")}
                                </div>
                            </div>
                        )
                    } : ""}
                    <div class="bg-zinc-800 rounded py-0.5">
                        <div class="m-3 justify-center">
                            <p class="text-zinc-300 text-2xl">Login to HighLife Job Tracker</p>
                        </div>

                        <div class="border-b border-zinc-700 m-2"></div>

                        <div class={`unhighlightable mt-6 gap-3 grid grid-cols-1 lg:grid-cols-6`}>
                            <button onClick={() => window.location.href = url} class="lg:col-span-4 lg:col-start-2 rounded-lg bg-[#586aea] py-2">
                                <img class="mx-auto h-6" src="/discord.png" />
                            </button>
                        </div>

                        <div class="m-3 mt-6">
                            <p class="text-xs text-zinc-300 font-semibold">By signing in through Discord:</p>
                            <p class="text-xs text-zinc-300">Your Discord login information won't be disclosed.</p>
                            <p class="text-xs text-zinc-300">Techstreet.tech will receive a special numerical identifier. This will allow techstreet.tech to recognise your Discord account profile and obtain data that will allow it to identify you.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	);
}

export default Login;
