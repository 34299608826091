import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PageNotFound from './routes/PageNotFound';
import Login from './routes/Login';
import Overview from './routes/dash/Overview';
import HomePage from './routes/HomePage';
import Tracker from './routes/dash/Tracker';
import Absences from './routes/dash/Absences';
import Settings from './routes/dash/Settings';
import PendingWarnings from './routes/dash/PendingWarnings';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<Routes>
			<Route exact path="/" element={<HomePage /> } />
			<Route exact path="/login" element={<Login /> } />
			<Route exact path="/dash/:id/overview" element={<Overview /> } />
			<Route exact path="/dash/:id/absences" element={<Absences /> } />
			<Route exact path="/dash/:id/warnings" element={<PendingWarnings /> } />
			<Route exact path="/dash/:id/settings" element={<Settings /> } />
			<Route exact path="/dash/:id/tracker" element={<Tracker /> } />
			<Route exact path="*" element={<PageNotFound />} />
		</Routes>
	</BrowserRouter>
);