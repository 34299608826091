import { Dialog, Transition, Listbox } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

export default function ViewAbsenceDialog(data) {
    const loa = data.loa
    const jobData = data.jobData

    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }
    
    function openModal() {
        setIsOpen(true)
    }
    
    return (
        <>
            <tr onClick={openModal} class={loa.active ? "border-b bg-zinc-700 hover:bg-[#4a4a52] cursor-pointer border-[#343434]" : "border-b bg-zinc-700 hover:bg-[#4a4a52] cursor-pointer text-zinc-500 border-[#343434]"}>
                <th scope="row" class="px-6 py-3 whitespace-nowrap">
                    <div class={`font-semibold text-l ${loa.active ?? "text-zinc-200"}`}>{loa.name ? loa.name : loa.steam}</div>
                </th>

                <td class="px-6">
                    {loa.reason}
                </td>

                <td class="px-6">
                    {Math.round(((loa.expiry) - (loa.start)) / 86400)}
                </td>

                <td class="px-6">
                    {new Date(loa.start * 1000).toLocaleDateString("en-GB")}
                </td>

                <td class="px-6">
                    {new Date(loa.expiry * 1000).toLocaleDateString("en-GB")}
                </td>

                <td class="px-6 text-right">
                    {loa.active ? <span class="font-bold flex-initial rounded bg-red-600 text-xs px-2 py-0.5 m-1 mt-0 ml-2 text-white">Active</span>
                        : <span class="font-bold flex-initial rounded bg-zinc-600 text-xs px-2 py-0.5 m-1 mt-0 ml-2 text-zinc-300">Expired</span>}
                </td>
            </tr>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="unhighlightable relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-zinc-600 p-6 text-left align-middle shadow-xl transition-all">
                        <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-zinc-100"
                        >
                            View Leave of Absence
                        </Dialog.Title>
                        
                        <div>
                            <p className="mt-2 text-sm text-zinc-300">{loa.name ? "Employee Name" : "Steam Identifier"}</p>
                            <input value={loa.name ? loa.name : loa.steam} disabled className="border border-zinc-500 cursor-not-allowed text-zinc-400 mt-1 w-full rounded-lg bg-[#656570] py-2 px-3.5 text-left shadow-md focus:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 sm:text-sm" />
                        </div>

                        <div>
                            <p className="mt-2 text-sm text-zinc-300">Reason</p>
                            <textarea disabled rows="3" style={{overflow:"auto", resize: "none"}} className="border border-zinc-500 cursor-not-allowed text-zinc-400 mt-1 w-full rounded-lg bg-[#656570] py-2 px-3.5 text-left shadow-md focus:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 sm:text-sm">{loa.reason}</textarea>
                        </div>

                        <div>
                            <p className="mt-2 text-sm text-zinc-300">Additional Information</p>
                            <textarea disabled rows="3" style={{overflow:"auto", resize: "none"}} className="border border-zinc-500 cursor-not-allowed text-zinc-400 mt-1 w-full rounded-lg bg-[#656570] py-2 px-3.5 text-left shadow-md focus:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 sm:text-sm">{loa.additional}</textarea>
                        </div>

                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
                </Dialog>
            </Transition>
        </>
    )
}